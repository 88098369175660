import React, { useEffect } from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import CmsContent from 'components/CmsContent';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import useExperimentPageCode from 'pages/CmsPage/useExperimentPageCode';
import SearchStructuredData from 'pages/Home/components/SearchStructuredData';
import useRetailMediaScript from 'hooks/useRetailMediaScript';
import { RetailMediaPageNames } from 'hooks/useRetailMediaScript.types';

const Home = () => {
  const handleRetailMediaTracking = useRetailMediaScript({
    pageName: RetailMediaPageNames.HOME,
  });

  useEffect(() => {
    requestIdleCallback(() => {
      handleRetailMediaTracking();
    });
  }, [handleRetailMediaTracking]);

  const pageCode = useExperimentPageCode('frontpage');

  return (
    <ErrorBoundary withStatusCode>
      <SearchStructuredData />
      <CmsContent pageCode={pageCode} contentSlot="content" seo />
    </ErrorBoundary>
  );
};

export default track(tagComponent('Home'), {
  dispatchOnMount: () => ({ event: 'pageShown' }),
})(Home);
