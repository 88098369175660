import {
  Generic,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from 'react-structured-data/dist/schemas';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import JSONLD from 'react-structured-data/dist/JSONLD';
import React, { useContext } from 'react';
import CountryContext from 'Providers/IntlProvider/CountryContext';

const SearchStructuredData = () => {
  const country = useContext<any>(CountryContext);

  const url = country?.shopUrl || '';
  const urlTemplate = `${url}/s/?s={search_term_string}`;

  return (
    <JSONLD dangerouslyExposeHtml>
      <Generic type="WebSite" jsonldtype="WebSite" schema={{ url }}>
        <Generic
          type="potentialAction"
          jsonldtype="SearchAction"
          schema={{ 'query-input': 'required name=search_term_string' }}
        >
          <Generic type="target" jsonldtype="EntryPoint" schema={{ urlTemplate }} />
        </Generic>
      </Generic>
    </JSONLD>
  );
};

export default SearchStructuredData;
