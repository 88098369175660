import { useContext } from 'react';
import ApplicationDataContext from 'pages/App/components/ApplicationDataProvider/ApplicationDataContext';

interface AdditionConfiguration {
  code: string;
  value: string;
}

const useAdditionalConfiguration = (...keys: string[]) => {
  const additionalConfigurations: AdditionConfiguration[] =
    useContext(ApplicationDataContext)?.config?.additionalConfigurations || [];

  return keys.map((key) => additionalConfigurations.find((config) => config.code === key)?.value);
};

export default useAdditionalConfiguration;
