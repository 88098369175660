import { useCallback, useRef, useEffect } from 'react';
import { useGreeting } from 'modules/customer-account/hooks/useGreeting';
import { sha256 } from 'crypto-hash';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import useConsent from 'hooks/useConsent';
import useEnv from 'hooks/useEnv';
import { useAuth } from 'modules/customer-authentication/AuthContext';
import { isProductionUrl } from 'utils/url';
import {
  CartT2SParams,
  CategoryViewT2SParams,
  OrderT2SParams,
  RetailMediaCartProps,
  RetailMediaCustomPageNamesType,
  RetailMediaListingProps,
  RetailMediaPageNames,
  RetailMediaPageNamesType,
  RetailMediaPredefinedPageNamesType,
  SearchViewT2SParams,
  T2sParamsInterface,
  UseRetailMediaScriptProps,
} from 'hooks/useRetailMediaScript.types';
import useAdditionalConfiguration from 'hooks/useAdditionalConfiguration';

const isDebugT2S = false; // enable full t2s script, to improve debugging

export const MIRAKLADS_VENDOR = 'cstm_miraklads';

export const customEventTypeMapping: Record<RetailMediaCustomPageNamesType, string> = {
  [RetailMediaPageNames.ADD_TO_CART]: '4000',
  [RetailMediaPageNames.SEARCH_RESULTS]: '2000',
  [RetailMediaPageNames.MPLP]: '1401',
  [RetailMediaPageNames.LIST_PAGINATION]: '0000', // NOT TO BE USED, the original pageId should be used
};

export const predefinedEventMapping: Record<RetailMediaPredefinedPageNamesType, string> = {
  [RetailMediaPageNames.ACCOUNT_CREATION_END]: '3000',
  [RetailMediaPageNames.ACCOUNT_CREATION_START]: '2800',
  [RetailMediaPageNames.CART]: '1600',
  [RetailMediaPageNames.CONTENT]: '2200',
  [RetailMediaPageNames.HOME]: '1000',
  [RetailMediaPageNames.LIST]: '1400',
  [RetailMediaPageNames.LOGIN]: '2600',
  [RetailMediaPageNames.NOT_FOUND]: '3600',
  [RetailMediaPageNames.POST_PAYMENT]: '2400',
  [RetailMediaPageNames.PRODUCT]: '1200',
  [RetailMediaPageNames.PURCHASE_BEGIN]: '3200',
  [RetailMediaPageNames.SEARCH_RESULTS_EMPTY]: '3400',
};

export const pageTypeToStringMapping: Record<RetailMediaPageNamesType | string, string> = {
  ...customEventTypeMapping,
  ...predefinedEventMapping,
};

const getListingParams = (updatedProperties: RetailMediaListingProps) => {
  const listingParams = {} as CategoryViewT2SParams | SearchViewT2SParams;
  listingParams.pageNbr = updatedProperties.pageNr?.toString();
  listingParams.iID = updatedProperties.productData?.join('|');

  if (
    (updatedProperties.pageName === RetailMediaPageNames.LIST ||
      updatedProperties.pageName === RetailMediaPageNames.MPLP) &&
    updatedProperties.categoryId &&
    typeof updatedProperties.categoryId === 'string'
  ) {
    (listingParams as CategoryViewT2SParams).aID = updatedProperties.categoryId.toLowerCase();
  }
  if (updatedProperties.pageName === RetailMediaPageNames.SEARCH_RESULTS) {
    (listingParams as SearchViewT2SParams).kW = updatedProperties.searchTerm;
  }

  return listingParams;
};

const getCartParams = (updatedProperties: RetailMediaCartProps) => {
  const cartParams = {} as CartT2SParams;

  const { cartData, pageName } = updatedProperties;

  const validEntries =
    cartData.entries?.filter((entry) => entry.product?.code && entry.totalPrice?.value) || [];

  cartParams.bS = cartData.subTotal.value.toString();
  cartParams.iID = validEntries.map((entry) => entry.product?.code).join('|');
  cartParams.priceL = validEntries
    .map((entry) => (entry.quantity ? `${entry.totalPrice?.value}` : '0'))
    .join('|');
  cartParams.pID = pageTypeToStringMapping[pageName];
  cartParams.qTE = validEntries.map((entry) => `${entry.quantity}`).join('|');
  cartParams.offerID = validEntries
    .filter((entry) => entry.shopInformation?.isExternal)
    .map((entry) => `${entry.product?.code}:${entry.product?.priceData?.offerAttributes?.offerId}`)
    .join('|');

  if (cartData.code && pageName === RetailMediaPageNames.POST_PAYMENT) {
    return { ...cartParams, oID: cartData.code } as OrderT2SParams;
  }

  return cartParams;
};

const generateScript = (isProduction = false) => {
  const script = document.createElement('script');
  const scriptName = isDebugT2S ? 't2s' : 't2s.min';
  script.src = isProduction
    ? `https://static.target2sell.com/${scriptName}.js`
    : `https://static-dev.eu1.target2sell.mirakl.net/${scriptName}.js`;
  script.async = true;

  return script;
};

const useRetailMediaScript = (props: UseRetailMediaScriptProps) => {
  const propertiesRef = useRef<UseRetailMediaScriptProps | null>(props);
  const scriptRef = useRef<HTMLScriptElement | null>(null);
  const isRetailMediaTrackingEnabled = useFeatureFlagsEnabled('poseidon.retail.media.enabled');
  const [retailMediaCID] = useAdditionalConfiguration('retail.media.CID');
  const isMirakleAdsCookieEnabled = useConsent(MIRAKLADS_VENDOR);

  const { loading, email } = useGreeting() as Record<string, string>;
  const { isAnonymous } = useAuth();
  const env = useEnv();

  useEffect(() => {
    // remove all T2S related window objects if the consent is not given
    if (!isMirakleAdsCookieEnabled) {
      // eslint-disable-next-line no-underscore-dangle
      delete window._t2sparams;
      delete window.T2S;
    }
  }, [isMirakleAdsCookieEnabled]);

  const getT2SWindowParams = useCallback(
    async (updatedProperties: UseRetailMediaScriptProps, isProduction = false) => {
      const { action = 'view', pageName, productData } = updatedProperties;

      // eslint-disable-next-line no-underscore-dangle
      const retailMediaParams = window._t2sparams || ({} as T2sParamsInterface);

      retailMediaParams.eN = action;
      retailMediaParams.hasRankOption = true;
      retailMediaParams.cID = retailMediaCID;

      if (email && !isAnonymous) {
        retailMediaParams.uEM = await sha256(email);
      }

      if (pageName !== RetailMediaPageNames.LIST_PAGINATION) {
        retailMediaParams.pID = pageName && pageTypeToStringMapping[pageName];
      }

      let pageParams;

      switch (pageName) {
        case RetailMediaPageNames.PRODUCT:
        case RetailMediaPageNames.ADD_TO_CART:
          if (typeof productData === 'string') {
            retailMediaParams.iID = productData;
          }
          break;
        case RetailMediaPageNames.SEARCH_RESULTS_EMPTY:
          retailMediaParams.kW = updatedProperties.searchTerm;
          break;
        case RetailMediaPageNames.MPLP:
        case RetailMediaPageNames.SEARCH_RESULTS:
        case RetailMediaPageNames.LIST:
        case RetailMediaPageNames.LIST_PAGINATION:
          pageParams = getListingParams(updatedProperties as RetailMediaListingProps);
          break;
        case RetailMediaPageNames.CART:
        case RetailMediaPageNames.POST_PAYMENT:
          pageParams =
            updatedProperties?.cartData?.entries &&
            getCartParams(updatedProperties as RetailMediaCartProps);
          break;
        default:
          break;
      }

      if (Object.values(customEventTypeMapping).includes(retailMediaParams.pID as string)) {
        // if a custom page event is triggered add extra validators
        // eslint-disable-next-line no-console
        console.log(`${pageName} is a value in customEventTypeMapping`);
      }

      return { ...retailMediaParams, ...pageParams };
    },

    [email, isAnonymous, retailMediaCID],
  );

  const initializeScriptAndSetProperties = useCallback(
    async (updatedProperties?: UseRetailMediaScriptProps) => {
      propertiesRef.current = { ...propertiesRef.current, ...updatedProperties };
      const { pageName, skip } = propertiesRef.current;

      if (isMirakleAdsCookieEnabled && isRetailMediaTrackingEnabled && retailMediaCID && !loading) {
        const isProduction = isProductionUrl(window.location.hostname) || env === 'p';
        const script = generateScript(isProduction);

        if (RetailMediaPageNames.LIST_PAGINATION !== pageName) {
          // eslint-disable-next-line no-underscore-dangle
          window._t2sparams = {};
        }

        if (scriptRef.current && !skip) {
          scriptRef.current.remove();
        }

        // eslint-disable-next-line no-underscore-dangle
        window._t2sparams = await getT2SWindowParams(propertiesRef.current, isProduction);

        if (!skip) {
          document.head.appendChild(script);
          scriptRef.current = script;
        }
      }
    },
    [
      env,
      isMirakleAdsCookieEnabled,
      isRetailMediaTrackingEnabled,
      loading,
      retailMediaCID,
      getT2SWindowParams,
    ],
  );

  useEffect(
    () => () => {
      // remove the script from the head on unmount
      if (scriptRef?.current?.src) {
        document.head.removeChild(scriptRef?.current);
      }
    },
    [],
  );

  // return the callback to initialize the script and set the properties
  return initializeScriptAndSetProperties;
};

export default useRetailMediaScript;
