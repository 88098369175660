import { useExperiment } from '@xxxlgroup/xxxl-a-b-testing';

// NOTE: this is only used because useExperiment hook requires non-empty string, otherwise 'Must pass a valid string as the experimentName' error is thrown
const dummyExperimentCode = 'abTest';

const experiments: { [key: string]: { name: string; variants: string[] } } = {
  prospektangebote: {
    name: 'cmsPocoBrochurePageTest',
    variants: ['prospektangebote', 'prospektangebote2'],
  },
  kuechenplanung: {
    name: 'cmsKitchenPlanningPageTest',
    variants: ['kuechenplanung', 'kuechenplanung2'],
  },
  frontpage: { name: 'homepageTest', variants: ['frontpage', 'frontpage0624-test'] },
  'online-top-deals': {
    name: 'topDealsPageTest',
    variants: ['online-top-deals', 'online-top-deals-2'],
  },
};

const useExperimentPageCode = (cmsPageCode: string): string => {
  const experimentData = experiments[cmsPageCode];
  const { variant } = useExperiment(experimentData?.name ?? dummyExperimentCode);

  return experimentData?.variants[Number(variant)] ?? cmsPageCode;
};

export default useExperimentPageCode;
